import React from 'react';


const NotificationItem = ({notification}) => {
  const { text, time} = notification;
  return (
    <li className="media">
      <div className="media-body align-self-center">
        <p className="sub-heading mb-0">{text}</p>
         <span className="meta-date"><small>{time}</small></span>
      </div>
    </li>
  );
};

export default NotificationItem;
