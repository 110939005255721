import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import CustomScrollbars from 'util/CustomScrollbars';
import ApoioUsuarioSide from './ApoioUsuarioSide';

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {

        const parentLiEle = that.closest(this, 'li');
        if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains('open')) {
            menuLi[i].classList.remove('open', 'active');
          } else {
            menuLi[i].classList.add('open', 'active');
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, 'li');
            if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
              menuLi[j].classList.remove('open');
            } else {
              if (menuLi[j].classList.contains('open')) {
                menuLi[j].classList.remove('open');
              } else {
                menuLi[j].classList.add('open');
              }
            }
          }
        }
      }
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  componentWillReceiveProps(nextProps) {

    const { history } = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">

          <li className="nav-header">
            Geral
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/dashboard">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li>
          <li className="menu no-arrow bg-success ml-3 mr-3 p-1">
            <NavLink to="/app/agendas/nova">
              <i className="zmdi zmdi-plus zmdi-hc-fw text-white" />
              <span className="nav-text text-white">Novo Agendamento</span>
            </NavLink>
          </li>
          <li className="nav-header">
            Interação
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/notificacao">
              <i className="zmdi zmdi-email zmdi-hc-fw" />
              <span className="nav-text">Notificações</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/agenda">
              <i className="zmdi zmdi-calendar zmdi-hc-fw" />
              <span className="nav-text">Agenda</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/agendas/cancelado">
              <i className="zmdi zmdi-delete zmdi-hc-fw" />
              <span className="nav-text">Cancelados</span>
            </NavLink>
          </li>
          <li className="nav-header">
            Doadores
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/doador">
              <i className="zmdi zmdi-accounts zmdi-hc-fw" />
              <span className="nav-text">Doadores</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/plaqueta">
              <i className="zmdi zmdi-account-box-phone zmdi-hc-fw" />
              <span className="nav-text">Plaquetas</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/falta">
              <i className="zmdi zmdi-account-box-o zmdi-hc-fw" />
              <span className="nav-text">Bloqueados por falta</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/reset/senha">
              <i className="zmdi zmdi-lock-open zmdi-hc-fw" />
              <span className="nav-text">Reset Senha</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/ativacao">
              <i className="zmdi zmdi-lock-open zmdi-hc-fw" />
              <span className="nav-text">Ativar App</span>
            </NavLink>
          </li>
          <li className="nav-header">
            Aplicativo
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/disponibilidade">
              <i className="zmdi zmdi-time zmdi-hc-fw" />
              <span className="nav-text">Disponibilidade</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/noticia">
              <i className="zmdi zmdi-comment-image zmdi-hc-fw" />
              <span className="nav-text">Notícias</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/faq">
              <i className="zmdi zmdi-comment-alert zmdi-hc-fw" />
              <span className="nav-text">Critério</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/informativo">
              <i className="zmdi zmdi-comment-list zmdi-hc-fw" />
              <span className="nav-text">Informativos</span>
            </NavLink>
          </li>
          <li className="nav-header">
            Gerência
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/profile">
              <i className="zmdi zmdi-account zmdi-hc-fw" />
              <span className="nav-text">Meu Perfil</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/local">
              <i className="zmdi zmdi-pin zmdi-hc-fw" />
              <span className="nav-text">Informações Gerais</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/sublocal">
              <i className="zmdi zmdi-pin-drop zmdi-hc-fw" />
              <span className="nav-text">Postos de coleta</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/feriado">
              <i className="zmdi zmdi-calendar-note zmdi-hc-fw" />
              <span className="nav-text">Feriados</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/campanha">
              <i className="zmdi zmdi-assignment zmdi-hc-fw" />
              <span className="nav-text">Campanhas</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/relatorio">
              <i className="zmdi zmdi-chart zmdi-hc-fw" />
              <span className="nav-text">Relatório</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/usuario">
              <i className="zmdi zmdi-account-add zmdi-hc-fw" />
              <span className="nav-text">Usuários</span>
            </NavLink>
          </li>
          <ApoioUsuarioSide />
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
