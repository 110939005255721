import React from 'react';
import { Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { LaConFetch } from '../../components/Functions/fetch';

class AgendaLocaisSide extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            locais:[],
            isOpen:false,
        }
    }

    componentDidMount(){
        LaConFetch(`api/sublocal`,(r)=>{
            if(r.success){
                this.setState({
                    locais:r.items
                })
            }
        },(e)=>{})
    }
    render() { 
        const { isOpen,locais } = this.state;
        return (<li className={`menu ${isOpen ? 'open':''} collapse-box`}>
            <Button onClick={()=>this.setState({isOpen: !isOpen})}>
                <i className="zmdi zmdi-calendar zmdi-hc-fw"/>
                <span className="nav-text">Agendas</span>
            </Button>
            <ul className="sub-menu">
                {locais.map(item=><li key={item.id}>
                    <NavLink className="prepend-icon" to={`/app/agenda/${item.id}`}>
                        <i className="zmdi zmdi-pin-drop zmdi-hc-fw"/>
                        <span className="nav-text">{item.prefixo || item.nome}</span>
                    </NavLink>
                </li>)}
            </ul>
        </li>);
    }
}
 
export default AgendaLocaisSide;