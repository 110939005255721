import React from 'react';
import NotificationItem from './NotificationItem';
import CustomScrollbars from 'util/CustomScrollbars';
import moment from 'moment';

const defWarn = {
  text: 'Não há nenhuma notificação',
  time: moment().format('hh:mm'),
}

class AppNotification extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      loaded: true,
      warns: [],
    }
  }
  render(){
    const {loaded,warns} = this.state;

    return (
      <CustomScrollbars className="messages-list scrollbar" style={{height: 140}}>
        <ul className="list-unstyled">
          {loaded && warns.length > 0 ? (
            warns.map((item,index)=>{return(<NotificationItem key={index} notification={item}/>)})
          ):(
            <NotificationItem key={1} notification={defWarn}/>
          )
          }
        </ul>
      </CustomScrollbars>
    )
  }
};

export default AppNotification;

